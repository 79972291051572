<template>
  <div class="q-pa-md">
    <q-table
      title="Projects"
      @row-click="evtRowClick"
      :rows="users"
      :columns="columns"
      :filter="filter"
      :dense="true"
      :pagination="{ rowsPerPage: 15 }"
    >
      <template v-slot:top-right>
        <q-input borderless dense debounce="300" v-model="filter" outlined placeholder="Search">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
    </q-table>
    <q-dialog
      v-model="userDialog"
    >
      <q-card style="width: 800px; max-width: 80vw;">
        <q-card-section>
            <div class="text-h6">{{ user.name }}</div>
        </q-card-section>
        <q-card-section>
            <div class="text-h6">App Roles</div>
            <q-list bordered separator v-for="role in roles" :key="role.id">
                <q-item clickable>
                    <q-item-section @click="addUserRole(role.id)"> {{ role.name }} </q-item-section>
                </q-item>
            </q-list>
        </q-card-section>
        <q-card-section>
            <div class="text-h6">User Roles</div>
            <q-list bordered separator v-for="userRole in userRoles" :key="userRole.id">
                <q-item clickable>
                    <q-item-section @click="deleteUserRole(userRole.id)"> {{ userRole.role.name }} </q-item-section>
                </q-item>
            </q-list>
        </q-card-section>
        <div class="q-pa-md">
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-4">

              </div>
            </div>
            <q-separator spaced />
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import authService from '../services/authService';
import swalService from '../services/swalService';

export default {
  components: [
  ],
  data() {
    return {
      userDialog: false,
      submitDisable: false,
      user: {},
      users: [],
      roles: [],
      userRoles: [],
      filter: '',
      columns: [
        { name: 'id', align: 'left', label: 'Id', field: 'id', sortable: true },
        { name: 'name', align: 'left', label: 'Name', field: 'name', sortable: true },
        { name: 'email', align: 'left', label: 'Email', field: 'email', sortable: true },
      ]
    }
  },
  created () {
    this.getUsers()
    this.getRoles()
  },
  methods: {
    async getUsers () {
        await authService.getUsers()
            .then(res => {
                this.users = res.data
            })
    },
    async getUser(userId) {
        await authService.getUser(userId)
            .then(res => {
                this.user = res.data
            })
    },
    async getUserRoles (userId) {
        await authService.getUserRoles(userId)
            .then(res => {
                this.userRoles = res.data
            })
    },
    async getRoles () {
        await authService.getRoles()
            .then(res => {
                this.roles = res.data
            })
    },
    async addUserRole (roleId) {
        const userRole = {
            roleId,
            userId: this.user.id
        }
        await authService.addUserRole(userRole)
            .then(function () {
                swalService.Success()
            })
        await this.getUserRoles(this.user.id)
    },
    async deleteUserRole (roleId) {
        await swalService.confirmDelete()
            .then(async res => {
                if (res.isConfirmed) {
                    await authService.deleteUserRole(roleId)
                        .then(function () {
                            swalService.Success()
                        })
                }
            })
        await this.getUserRoles(this.user.id)
    },
    async evtRowClick (event, user) {
        await this.getUser(user.id)
        await this.getUserRoles(user.id)
        this.userDialog = true
    }
  }
}
</script>
